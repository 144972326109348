<template>
  <div
    :data-id="DataIdMolecule.MOLECULE_ALERT_BADGE"
    class="molecule-alert-badge gap-16 justify-content-between px-24 py-16 mt-16 mb-16 mb-md-8"
    :class="{
      [statusColor]: !!status,
      'd-flex': visible,
      'd-none': !visible,
      'with-shadow': withShadow,
    }"
  >
    <div class="d-flex flex-column flex-md-row pe-md-48 gap-16">
      <div>
        <component :is="statusIcon" class="icon" :class="{ rotate: status === Status.WARNING }" />
      </div>

      <div class="d-flex flex-column gap-4">
        <div class="paragraph-small-heavy text-base-black">
          {{ title }}
        </div>
        <div class="description-wrapper paragraph-small text-base-grey">
          <slot name="description" />
        </div>
      </div>
    </div>
    <button
      v-if="withCloseBtn"
      class="close-btn mx-16 my-12 mx-md-24 my-md-16"
      @click="handleClose"
    >
      <icon-close />
    </button>
  </div>
</template>

<script lang="ts" setup>
import IconClose from '@web/packeta-ui-styleguide/icons/map/icon-close.svg'
import IconEmojiSad from '@web/packeta-ui-styleguide/icons/status/icon-emoji-sad.svg'
import IconEmojiSmile from '@web/packeta-ui-styleguide/icons/status/icon-emoji-smile.svg'
import IconInfoCircle from '@web/packeta-ui-styleguide/icons/status/icon-info.svg'
import { computed } from 'vue'
import { Emits, Status } from '~common/enums'
import { DataIdMolecule } from '~common/enums/tests.ts'
import type { MoleculeAlertBadgeEmits, MoleculeAlertBadgeProps } from '~common/types'

const props = defineProps<MoleculeAlertBadgeProps>()
const emits = defineEmits<MoleculeAlertBadgeEmits>()

const statusIcon = computed(() => {
  switch (props.status) {
    case Status.SUCCESS:
      return IconEmojiSmile
    case Status.WARNING:
    case Status.INFO:
      return IconInfoCircle
    default:
      return IconEmojiSad
  }
})

const statusColor = computed(() => {
  switch (props.status) {
    case Status.SUCCESS:
      return 'text-states-green-dark'
    case Status.WARNING:
      return 'text-states-orange-dark'
    case Status.INFO:
      return 'text-states-info-dark'
    default:
      return 'text-base-red'
  }
})

const handleClose = () => {
  emits(Emits.CLOSE)
}
</script>

<style lang="scss" scoped>
.molecule-alert-badge {
  border-left: $space-4 solid;
  background: $base-white;
  position: relative;
}

.close-btn {
  background: transparent;
  border: none;
  color: $grey-300;
  height: fit-content;
  position: absolute;
  top: 0;
  right: 0;

  @include focus-visible('solid', $grey-300);
}

.close-btn:hover {
  color: $base-black;
}

.description-wrapper:deep(ul) {
  list-style-type: square;

  li {
    margin-bottom: $space-4;
  }

  li::marker {
    font-size: 1em;
    color: $grey-300;
  }
}

.icon.rotate {
  rotate: 180deg;
}

.with-shadow {
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
}
</style>
